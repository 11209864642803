import React from "react"

import { graphql, useStaticQuery, Link } from "gatsby"

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';

export default () => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      bigcate:allContentfulCategory(filter: {navadd: {eq: "add"}}) {
        edges {
          node {
            navadd
            category
            categorySlug
            categoryS1
            categoryS2
            categoryS3
            categoryS4
          }
        }
      }
      allcate:allContentfulCategory(sort: {fields: createdAt, order: ASC}) {
        edges {
          node {
            category
            categorySlug
            categoryS1
            categoryS2
            categoryS3
            categoryS4
          }
        }
      }
    }
  `)

  const nav = () => (
    <ul className="inner">
      <li><Link to={`/`}>top</Link></li>
      <li><Link to={`/about/`}>about</Link></li>
      {data.bigcate.edges.map((bigcate) => (
        <li><div>
          <Link to={`/${bigcate.node.categorySlug}/`}>{bigcate.node.categorySlug}</Link>
          <ul className="nav_lower">

            {data.allcate.edges.map(allcate => (<>
              {bigcate.node.categoryS1 === allcate.node.categorySlug && (<>
                <li>
                  <Link to={`/${allcate.node.categorySlug}/`}>{allcate.node.category}</Link>

                  <ul className="smallcate">
                    {data.allcate.edges.map(allcateNew => (<>
                      {`no-category` !== allcate.node.categoryS1 && (
                        allcate.node.categoryS1 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS1}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                      {`no-category` !== allcate.node.categoryS2 && (
                        allcate.node.categoryS2 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS2}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                      {`no-category` !== allcate.node.categoryS3 && (
                        allcate.node.categoryS3 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS3}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                      {`no-category` !== allcate.node.categoryS4 && (
                        allcate.node.categoryS4 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS4}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                    </>))}
                  </ul>
                </li>
              </>)}
              {bigcate.node.categoryS2 === allcate.node.categorySlug && (<>
                <li>
                  <Link to={`/${allcate.node.categorySlug}/`}>{allcate.node.category}</Link>

                  <ul className="smallcate">
                    {data.allcate.edges.map(allcateNew => (<>
                      {`no-category` !== allcate.node.categoryS1 && (
                        allcate.node.categoryS1 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS1}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                      {`no-category` !== allcate.node.categoryS2 && (
                        allcate.node.categoryS2 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS2}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                      {`no-category` !== allcate.node.categoryS3 && (
                        allcate.node.categoryS3 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS3}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                      {`no-category` !== allcate.node.categoryS4 && (
                        allcate.node.categoryS4 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS4}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                    </>))}
                  </ul>
                </li>
              </>)}
              {bigcate.node.categoryS3 === allcate.node.categorySlug && (<>
                <li>
                  <Link to={`/${allcate.node.categorySlug}/`}>{allcate.node.category}</Link>

                  <ul className="smallcate">
                    {data.allcate.edges.map(allcateNew => (<>
                      {`no-category` !== allcate.node.categoryS1 && (
                        allcate.node.categoryS1 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS1}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                      {`no-category` !== allcate.node.categoryS2 && (
                        allcate.node.categoryS2 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS2}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                      {`no-category` !== allcate.node.categoryS3 && (
                        allcate.node.categoryS3 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS3}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                      {`no-category` !== allcate.node.categoryS4 && (
                        allcate.node.categoryS4 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS4}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                    </>))}
                  </ul>
                </li>
              </>)}
              {bigcate.node.categoryS4 === allcate.node.categorySlug && (<>
                <li>
                  <Link to={`/${allcate.node.categorySlug}/`}>{allcate.node.category}</Link>

                  <ul className="smallcate">
                    {data.allcate.edges.map(allcateNew => (<>
                      {`no-category` !== allcate.node.categoryS1 && (
                        allcate.node.categoryS1 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS1}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                      {`no-category` !== allcate.node.categoryS2 && (
                        allcate.node.categoryS2 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS2}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                      {`no-category` !== allcate.node.categoryS3 && (
                        allcate.node.categoryS3 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS3}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                      {`no-category` !== allcate.node.categoryS4 && (
                        allcate.node.categoryS4 === allcateNew.node.categorySlug && (
                          <li><Link to={`/${allcate.node.categoryS4}/`}>{allcateNew.node.category}</Link></li>
                        )
                      )}
                    </>))}
                  </ul>
                </li>
              </>)}
            </>))}
          </ul>
        </div></li>
      ))}
      <li><Link to={`/sitemap/`}>sitemap</Link></li>
    </ul>
  );

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  return <header className="header">
    <div className="container">
      <div className="site logo">
        <Link to={`/`}>
        Henri Morhange <span className="sub">{data.site.siteMetadata.title}</span>
        </Link>
      </div>
      <React.Fragment key={`top`}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <Drawer variant="persistent" anchor="top" open={open} className={`menu layout_nm`}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
          {nav()}
        </Drawer>
      </React.Fragment>
      <nav className="nav layout_nm">
        {nav()}
      </nav>
    </div>
  </header >
}
