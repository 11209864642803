import React from "react"

import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faFacebookSquare, faInstagram } from "@fortawesome/free-brands-svg-icons"

export default () => {

  const data = useStaticQuery(graphql`
    query {
      pattern:file(relativePath: {eq: "lace1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return <footer className="footer">
    <p className="ptop">
      <a href="#page">
        page top
      </a>
    </p>
    <div className="bg">
      <Img fluid={data.pattern.childImageSharp.fluid} style={{ height: "100%" }} alt="" />
    </div>
    <div className="container">
      <div className="siteBox">
        <Link to={`/`}>
          <p className="logo">Henri Morhange <span className="sub">{data.site.siteMetadata.title}</span></p>
          <p className="text">{data.site.siteMetadata.description}</p>
        </Link>
      </div>
      <ul className="sns">
        <li>
          <OutboundLink href="https://twitter.com/HMorhange" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faTwitter} />
            <span className="sr-only">Twitter</span>
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://facebook.com/" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faFacebookSquare} />
            <span className="sr-only">Facebook</span>
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://instagram.com/henrimorhange" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faInstagram} />
            <span className="sr-only">Instagram</span>
          </OutboundLink>
        </li>
      </ul>
    </div>
  </footer>
}