import React from "react"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

import { isIOS } from "react-device-detect"

import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false

export default ({ children }) => {

    if (isIOS) {
        let body = document.getElementsByTagName('body')[0];
        body.classList.add('iPhone');
    }

    return <div id="page">
        <Header />
        {children}
        <Footer />
    </div>
}
