import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export default props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          keywords
          lang
          title
          siteUrl
          locale
          fbappid
          image
        }
      }
    }
  `)

  const title = props.pagetitle
    ? `${props.pagetitle}|${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title

  const description = props.pagedesc || data.site.siteMetadata.description

  const keywords = props.pagekeys ? `${props.pagekeys},${data.site.siteMetadata.keywords}` : data.site.siteMetadata.keywords

  const url = props.pagepath
    ? `${data.site.siteMetadata.siteUrl}${props.pagepath}`
    : data.site.siteMetadata.siteUrl

  const image = props.image
    ? `${data.site.siteMetadata.siteUrl}${props.image}`
    : props.blogimage || `${data.site.siteMetadata.siteUrl}/thumb.jpg`

  const imageW = props.imageW || 1280
  const imageH = props.imageH || 640


  return (
    <Helmet>
      <html lang={data.site.siteMetadata.lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={url} />

      <meta property="og:site_name" content={`Henri Morhange ${data.site.siteMetadata.title}`} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={data.site.siteMetadata.locale} />
      <meta property="fb:app_id" content={data.site.siteMetadata.fbappid} />

      <meta property="og:image" content={image} />
      <meta property="og:image:width" content={imageW} />
      <meta property="og:image:height" content={imageH} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@HMorhange" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}